<template>
  <v-container class="">
    <v-row no-gutters>
      <v-app-bar color="white" flat>
        <v-breadcrumbs :items="items"></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-app-bar>
    </v-row>
    <v-row no-gutters>
      <v-tabs color="blue accent-4" centered>
        <v-tab>MOUS</v-tab>
        <v-tab>OVERVIEW</v-tab>

        <v-tab-item>
          <mou-list></mou-list>
        </v-tab-item>
        <v-tab-item>
          <overview></overview>
        </v-tab-item>
      </v-tabs>
    </v-row>
    <!-- <v-row class="text-center">
      <mou-table></mou-table>
    </v-row> -->
    <add-mou-dialog></add-mou-dialog>
  </v-container>
</template>

<script>
import Overview from "@/components/mous/OverviewTab.vue";
import MouList from "@/components/mous/MouListTab.vue";

import AddMouDialog from "@/components/mous/AddMouDialog.vue";

export default {
  components: {
    Overview,
    MouList,
    AddMouDialog,
  },
  data: () => ({
    items: [
      {
        text: "DASHBOARD",
        disabled: false,
        href: "/",
      },
      {
        text: "MOU",
        disabled: true,
        href: "",
      },
    ],
  }),
};
</script>

<style scoped>
.me-red {
  border: 1px solid #ebedf0;
}
</style>
