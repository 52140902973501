<template>
  <v-card flat class="border mx-auto">
    <v-card flat>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="SEARCH"
        single-line
        hide-details
        dense
        outlined
        class="ma-6"
      ></v-text-field>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="institutes"
      :search="search"
      class="v-align"
      @click:row="handleClick"
    >
      <template v-slot:item.title="{ item }">
        {{ item.title }}
      </template>
      <!-- <template v-slot:item.status="{ item }">
        <v-btn icon color="blue">
          <v-icon size="20px">mdi-open-in-new</v-icon>
        </v-btn>
      </template> -->
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "NAME", value: "title" },
        { text: "PEN.", value: "mouProceeding" },
        { text: "AME.", value: "mouAmendments" },
        { text: "APR.", value: "mouApproved" },
      ],
      message: "",
    };
  },
  computed: {
    institutes() {
      return this.$store.getters["approvers/approvers"];
    },
  },
  methods: {
    handleClick(item) {
      this.$router.push({
        name: "approver",
        params: { id: item.id },
      });
    },
    loadInstitutes() {
      this.$store.dispatch("institutes/getInstitutes");
    },
  },
};
</script>

<style>
table td,
table th {
  vertical-align: middle !important;
}
.flag-container {
  font-size: 18px;
  margin-right: 5px;
}
</style>
