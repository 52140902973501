<template>
  <v-card flat class="border mx-auto" max-width="1020">
    <v-card flat>
      <div class="pa-2 mx-4">
        <v-btn block tile color="info" @click="showAddMouDialog()">
          ADD MOU
        </v-btn>
      </div>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="SEARCH"
        single-line
        hide-details
        dense
        outlined
        class="ma-6 mb-4"
      ></v-text-field>
      <v-tabs color="red accent-4" class="my-6" centered v-model="activeTab">
        <v-tab key="all">ALL</v-tab>
        <v-tab key="approved">APPROVED</v-tab>
        <v-tab key="proceeding">PROCEEDING</v-tab>
        <v-tab key="amendments">AMENDMENTS</v-tab>
        <v-tab key="inMohe">IN MOHE</v-tab>
      </v-tabs>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="filteredMou"
      :search="search"
      class="v-align"
    >
      <template v-slot:item.createdAt="{ item }">
        {{ getDate(item.createdAt) }}

        <!-- <v-icon dense :color="getColor(item.status)">
          {{ getIcon(item.status) }}</v-icon
        > -->
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip :color="getColor(item.status)" label dark small outlined>
          {{ getText(item.status) }}
        </v-chip>
        <!-- <v-icon dense :color="getColor(item.status)">
          {{ getIcon(item.status) }}</v-icon
        > -->
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn icon color="blue" @click="goToMOU(item)">
          <v-icon size="20px">mdi-open-in-new</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      search: "",
      activeTab: "",
      headers: [
        {
          text: "CODE",
          align: "start",
          sortable: false,
          value: "code",
        },
        { text: "TITLE", value: "title" },
        { text: "DATE", value: "createdAt" },
        { text: "L.INSTITUTE", value: "localInstitute" },
        { text: "F.INSTITUTE", value: "foreignInstitute" },
        { text: "STATUS", align: "center", value: "status" },
        { text: "ACTION", align: "end", value: "action" },
      ],

      message: "",
    };
  },
  computed: {
    userMous() {
      return this.$store.getters["mous/userMous"];
    },

    filteredMou() {
      let selection = this.activeTab;
      if (selection == 0) {
        return this.mous;
      }

      if (selection == 1) {
        return this.mous.filter((el) => el.status == "approved");
      }

      if (selection == 2) {
        return this.mous.filter((el) => el.status == "proceeding");
      }

      if (selection == 3) {
        return this.mous.filter((el) => el.status == "amendments");
      }
      if (selection == 4) {
        return this.mous.filter((el) => el.status == "in mohe");
      }
    },
  },
  methods: {
    showAddMouDialog() {
      this.$store.dispatch("mous/setAddMouDialogVisibility", true);
    },

    getColor(item) {
      if (item == "amendments") {
        return "#FFC107";
      }
      if (item == "approved") {
        return "#4CAF50";
      }
      if (item == "proceeding") {
        return "#2196F3";
      }
      if (item == "in mohe") {
        return "#9C27B0";
      }
    },
    getText(item) {
      if (item == "amendments") {
        return "AMENDMENTS";
      }
      if (item == "approved") {
        return "APPROVED";
      }
      if (item == "proceeding") {
        return "PROCEEDING";
      }
      if (item == "in mohe") {
        return "IN MOHE";
      }
    },
    getIcon(item) {
      if (item == "amendments") {
        return "mdi-file-document-edit-outline";
      }
      if (item == "approved") {
        return "mdi-check-decagram-outline";
      }
      if (item == "proceeding") {
        return "mdi-timer-sand-complete";
      }
      if (item == "in mohe") {
        return "mdi-archive-clock-outline";
      }
    },
    getDate(value) {
      const date = new Date(value);
      return (
        date.getDate().toString() +
        "-" +
        (date.getMonth() + 1).toString() +
        "-" +
        date.getFullYear().toString()
      );
    },
    goToMOU(item) {
      const id = item._id;
      this.$router.push({
        name: "mou",
        params: { id },
      });
    },
  },
};
</script>

<style>
table td,
table th {
  vertical-align: middle !important;
}
</style>
