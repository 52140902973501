<template>
  <h1>
    <v-chart class="chart" :option="option" />
  </h1>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: "InstituteMOUChart",
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },

        series: [
          {
            name: "System MoU Status",
            type: "pie",
            radius: ["60%", "74%"],
            center: ["50%", "60%"],

            data: [
              { value: 1, name: "Ministry Of Education" },
              { value: 7, name: "Ministry Of Health" },
              { value: 12, name: "Ministry Of Home Affairs" },
              { value: 4, name: "Ministry Of Defense" },
              { value: 2, name: "Cabinet" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
              label: {
                show: true,
                fontSize: "20",
              },
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
