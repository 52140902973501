<template>
  <v-card flat class="border mx-auto" max-width="580">
    <v-card flat>
      <div class="pa-2 mx-4">
        <v-btn block tile color="info" @click="showAddInstituteDialog()">
          ADD INSTITUTE
        </v-btn>
      </div>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="SEARCH"
        single-line
        hide-details
        dense
        outlined
        class="ma-6"
      ></v-text-field>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="institutes"
      :search="search"
      class="v-align"
    >
      <template v-slot:item.status="{ item }">
        <v-btn icon color="blue" @click="goToInstitute(item._id)">
          <v-icon size="20px">mdi-open-in-new</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "CODE", value: "code" },
        { text: "NAME", value: "name" },
        { text: "ACTION", align: "end", value: "status" },
      ],
      message: "",
    };
  },
  computed: {
    institutes() {
      return this.$store.getters["institutes/institutes"];
    },
  },
  methods: {
    showAddInstituteDialog() {
      this.$store.dispatch("institutes/setAddInstituteDialogVisibility", true);
    },

    goToInstitute(id) {
      console.log(id);
      this.$router.push({
        name: "institute",
        params: { id },
      });
    },

    loadInstitutes() {
      this.$store.dispatch("institutes/getInstitutes");
    },
  },
  created() {
    this.loadInstitutes();
  },
};
</script>

<style>
table td,
table th {
  vertical-align: middle !important;
}
</style>
