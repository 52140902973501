<template>
  <v-container class="">
    <v-row no-gutters>
      <v-col cols="12" md="7" class="px-1 pt-4">
        <active-country-pie-chart></active-country-pie-chart>
        <active-country-bar-chart></active-country-bar-chart>
      </v-col>

      <v-col cols="12" md="5" class="px-1 pt-2">
        <active-country-table></active-country-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ActiveCountryPieChart from "@/components/countries/ActiveCountryPieChart.vue";
import ActiveCountryBarChart from "@/components/countries/ActiveCountryBarChart.vue";
import ActiveCountryTable from "@/components/countries/ActiveCountryTable.vue";
export default {
  components: {
    ActiveCountryPieChart,
    ActiveCountryBarChart,
    ActiveCountryTable,
  },
  data: () => ({}),
};
</script>

<style scoped>
.me-red {
  border: 1px solid #ebedf0;
}
</style>
