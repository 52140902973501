<template>
  <v-container class="">
    <v-row no-gutters>
      <v-col cols="12" md="12" class="pa-4">
        <institute-table></institute-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InstituteTable from "@/components/institutes/InstituteTable.vue";

export default {
  components: {
    InstituteTable,
  },
  data: () => ({}),
};
</script>

<style scoped>
.me-red {
  border: 1px solid #ebedf0;
}
</style>
