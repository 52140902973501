<template>
  <v-container class="">
    <v-row no-gutters>
      <v-app-bar color="white" flat>
        <v-breadcrumbs :items="items"></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-app-bar>
    </v-row>
    <v-row no-gutters>
      <v-tabs color="blue accent-4" centered>
        <v-tab>OVERVIEW</v-tab>
        <v-tab>COUNTRIES</v-tab>

        <v-tab-item>
          <overview></overview>
        </v-tab-item>
        <v-tab-item>
          <list></list>
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<script>
import Overview from "@/components/countries/OverviewTab.vue";
import List from "@/components/countries/CountryListTab.vue";

export default {
  components: {
    Overview,
    List,
  },
  props: ["id"],
  data: () => ({
    items: [
      {
        text: "DASHBOARD",
        disabled: false,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "COUNTRIES",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],
  }),
};
</script>

<style scoped>
.me-red {
  border: 1px solid #ebedf0;
}
</style>
