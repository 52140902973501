<template>
  <v-row justify="center">
    <v-dialog v-model="formVisibility" persistent max-width="600px">
      <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
        <v-card>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-toolbar-title>
              <span class="text-h6">CREATE INSTITUTE</span></v-toolbar-title
            >

            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    name="Code"
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="code"
                      label="CODE"
                      single-line
                      dense
                      outlined
                      class="mx-2 my-2"
                      required
                      :error-messages="errors[0]"
                      :success="valid"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    name="Name"
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="name"
                      label="NAME"
                      single-line
                      dense
                      outlined
                      class="mx-2 my-2"
                      required
                      :error-messages="errors[0]"
                      :success="valid"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
            <v-alert
              prominent
              type="success"
              class="mx-2 my-2"
              :value="addInstituteApiActionStatus.status == 'success'"
            >
              <v-row align="center">
                <v-col class="grow">
                  Institute Added Successfully
                </v-col>
                <v-col class="shrink"> </v-col>
              </v-row>
            </v-alert>
            <v-alert
              prominent
              type="error"
              class="mx-2 my-2"
              :value="addInstituteApiActionStatus.status == 'failed'"
            >
              <v-row align="center">
                <v-col class="grow">
                  Action Failed {{ addInstituteApiActionStatus.message }}
                </v-col>
              </v-row>
            </v-alert>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              ripple
              depressed
              min-width="120"
              @click="close()"
            >
              CLOSE
            </v-btn>
            <v-btn
              v-if="showSubmitButton"
              color="blue darken-1"
              ripple
              depressed
              min-width="120"
              @click="handleSubmit(save)"
              :disabled="invalid"
            >
              SUBMIT
            </v-btn>
          </v-card-actions>
          <v-progress-linear
            :active="addInstituteApiActionStatus.isActive"
            :indeterminate="addInstituteApiActionStatus.isActive"
            absolute
            bottom
            height="6"
            color="deep-purple accent-4"
          ></v-progress-linear>
        </v-card>
      </validation-observer>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    isFormEnable: true,
    loading: true,
    code: "",
    name: "",
    isSubmitting: false,
    isFormDisabled: false,
    showSubmitButton: true,
  }),
  computed: {
    formVisibility() {
      return this.$store.getters["institutes/addInstituteDialogVisibility"];
    },

    authUser() {
      return this.$store.getters["auth/authUser"];
    },

    addInstituteApiActionStatus() {
      const status = this.$store.getters["app/apiActionByName"](
        "createInstitute"
      );

      if (status) {
        return status;
      }
      return {
        status: null,
        startTime: null,
        endTime: null,
        message: null,
        isActive: false,
        isRed: false,
      };
    },
  },
  watch: {
    addInstituteApiActionStatus(status) {
      if (status.status == "success") {
        this.isFormDisabled = true;
        this.showSubmitButton = false;
      }
      if (status.status == "failed") {
        this.isFormDisabled = false;
        this.showSubmitButton = true;
      }
    },
  },
  methods: {
    save() {
      this.isFormDisabled = true;
      const institute = {
        code: this.code,
        name: this.name,
        createdBy: this.authUser._id,
      };
      this.$store.dispatch("institutes/createInstitute", institute);
    },

    close() {
      this.resetFormVariables();
      this.resetValidationErrors();
      this.removeAddInstituteApiAction();
      this.$store.dispatch("institutes/setAddInstituteDialogVisibility", false);
    },

    resetFormVariables() {
      this.isFormEnable = true;
      this.loading = true;
      this.code = "";
      this.name = "";
      this.isSubmitting = false;
      this.isFormDisabled = false;
      this.showSubmitButton = true;
    },
    removeAddInstituteApiAction() {
      this.$store.dispatch("app/removeApiAction", "createInstitute");
    },
    resetValidationErrors() {
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },
  },
};
</script>
