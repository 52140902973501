<template>
  <v-container class="">
    <div class="timeline ">
      <header class="timeline-header">
        <span class="tag is-medium is-primary">CREATED</span>
      </header>

      <div class="timeline-item is-primary">
        <div class="timeline-marker is-primary"></div>
        <div class="timeline-content">
          <p class="heading">
            <i class="mdi mdi-calendar-range"></i>
            {{ moment(mou.createdAt).format("YYYY-MM-DD") }}
            <i class="mdi mdi-clock-time-four-outline"></i>
            {{ moment(mou.createdAt).format("hh:mm A") }}
          </p>
          <div>
            <p class="heading ">
              <i class="mdi mdi-account-outline mr-2"></i>
              BY:
              {{ mou.createdBy.name }}
              <br />
              <br />
              <i class="mdi mdi-comment-text-outline mr-2"></i>

              {{ mou.comment }}
            </p>
          </div>
        </div>
      </div>

      <span v-for="event in mouEvents" :key="event._id">
        <span v-if="event.eventType == 'send'">
          <header class="timeline-header">
            <span class="tag is-small is-primary">SENT</span>
          </header>

          <div class="timeline-item is-primary">
            <div class="timeline-marker is-primary"></div>
            <div class="timeline-content">
              <p class="heading">
                <i class="mdi mdi-calendar-range"></i>
                {{ moment(event.createdAt).format("YYYY-MM-DD") }}
                <i class="mdi mdi-clock-time-four-outline"></i>
                {{ moment(event.createdAt).format("hh:mm A") }}
              </p>

              <div>
                <p class="heading ">
                  <i class="mdi mdi-account-outline mr-2"></i>
                  FOR:
                  <span class="tag is-primary is-light">{{
                    event.sendFor
                  }}</span>
                  <br />
                  <br />
                  <i class="mdi mdi-email-send-outline mr-2"></i>
                  TO:
                  {{ event.sendTo.name }}
                  <br />
                  <!-- <i class="mdi mdi-arrange-send-to-back mr-2"></i>
                  FROM: -->
                  <!-- {{ event.sendFrom.name ? event.sendFrom.name : "" }} -->
                  <!-- <br /> -->
                  <i class="mdi mdi-account-outline mr-2"></i>
                  BY:
                  {{ event.createdBy.name }}
                  <br />
                  <br />
                  <i class="mdi mdi-comment-text-outline mr-2"></i>

                  {{ event.comment }}
                </p>
              </div>
            </div>
          </div>
        </span>

        <span v-if="event.eventType == 'receive'">
          <header class="timeline-header">
            <span class="tag is-small is-primary">RECEIVE</span>
          </header>

          <div class="timeline-item is-primary">
            <div class="timeline-marker is-primary"></div>
            <div class="timeline-content">
              <p class="heading">
                <i class="mdi mdi-calendar-range"></i>
                {{ moment(event.createdAt).format("YYYY-MM-DD") }}
                <i class="mdi mdi-clock-time-four-outline"></i>
                {{ moment(event.createdAt).format("hh:mm A") }}
              </p>

              <div>
                <p class="heading ">
                  <i class="mdi mdi-account-outline mr-2"></i>
                  FOR:
                  <span class="tag is-primary is-light">{{
                    event.receiveFor
                  }}</span>
                  <br />
                  <!-- <br />
                  <i class="mdi mdi-email-send-outline mr-2"></i>
                  TO:
                  {{ event.receiveTo.name }}
                  -->
                  <br />
                  <i class="mdi mdi-arrange-send-to-back mr-2"></i>
                  FROM:
                  {{ event.receiveFrom.name }}
                  <br />
                  <i class="mdi mdi-account-outline mr-2"></i>
                  BY:
                  {{ event.createdBy.name }}
                  <br />
                  <br />
                  <i class="mdi mdi-comment-text-outline mr-2"></i>

                  {{ event.comment }}
                </p>
              </div>
            </div>
          </div>
        </span>
      </span>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  props: ["id"],
  data: function() {
    return {
      items: [
        {
          text: "DASHBOARD",
          disabled: false,
          href: "/",
        },
        {
          text: "MOUs",
          disabled: false,
          href: "/mous",
        },
        {
          text: this.id,
          disabled: true,
          href: "",
        },
      ],
    };
  },
  computed: {
    mou() {
      return this.$store.getters["mous/mou"];
    },
    mouEvents() {
      return this.mou.events;
    },
  },
  methods: {
    moment: function(date) {
      return moment(date);
    },

    numberOfDays: function(firstDate, secondDate = Date.now()) {
      const oneDay = 24 * 60 * 60 * 1000;
      const _firstDate = new Date(firstDate);
      const _secondDate = new Date(secondDate);

      return Math.round(Math.abs((_firstDate - _secondDate) / oneDay));
    },

    date: function(firstDate, secondDate = Date.now()) {
      const oneDay = 24 * 60 * 60 * 1000;
      const _firstDate = new Date(firstDate);
      const _secondDate = new Date(secondDate);

      return Math.round(Math.abs((_firstDate - _secondDate) / oneDay));
    },

    time: function(dateTime) {
      const time = new Date(dateTime);
      return time.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    },

    dateTime: function(dateTime) {
      return this.date(dateTime) + " " + this.time(dateTime);
    },

    getMou() {
      this.$store.dispatch("mous/getMouById", this.id);
    },
  },
  mounted() {
    // this.getMou();
    console.log(this.id);
  },
};
</script>

<style>
table td,
table th {
  vertical-align: middle !important;
}
</style>
