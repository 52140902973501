<template>
  <v-card flat class="border mx-auto">
    <v-card flat>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="SEARCH"
        single-line
        hide-details
        dense
        outlined
        class="ma-6"
      ></v-text-field>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="countryMOU"
      :search="search"
      class="v-align"
      @click:row="handleClick"
    >
      <template v-slot:item.status="{ item }">
        <v-chip :color="get_color(item.status)" label dark small outlined>
          {{ get_text(item.status) }}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      headers: [
        {
          text: "REF NO",
          align: "start",
          sortable: false,
          value: "reference_no",
        },
        { text: "TITLE", value: "title" },
        { text: "SUBMIT DATE", value: "submit_date" },
        { text: "STATUS", align: "center", value: "status" },
      ],

      message: "",
    };
  },
  computed: {
    countryMOU() {
      return [
        {
          title: "T1 Lorem Ipsum",
          submit_date: "2021/10/12",
          university: "UOP",
          institute: "XYZ",
          status: 0,
          reference_no: "1234",
        },
        {
          title: "T2 Lorem Ipsum",
          submit_date: "2021/01/12",
          university: "UOP",
          institute: "XXZ",
          status: 1,
          reference_no: "1235",
        },
        {
          title: "T3 Lorem Ipsum B1",
          submit_date: "2021/02/12",
          university: "UOC",
          institute: "YYXX",
          status: 0,
          reference_no: "1236",
        },
        {
          title: "T4 Lorem Ipsum X001",
          submit_date: "2021/02/12",
          university: "UOC",
          institute: "YYXX",
          status: 2,
          reference_no: "1237",
        },
        {
          title: "T5 Lorem Ipsum BFX",
          submit_date: "2021/03/12",
          university: "UOP",
          institute: "XXYY",
          status: 3,
          reference_no: "1238",
        },
        {
          title: "T74 Lorem Ipsum BFX32",
          submit_date: "2021/03/12",
          university: "UOC",
          institute: "XXYY",
          status: 0,
          reference_no: "1239",
        },
        {
          title: "T422 Lorem Ipsum 2247BFX",
          submit_date: "2021/03/14",
          university: "UOC",
          institute: "XYZB",
          status: 1,
          reference_no: "1240",
        },
        {
          title: "T42 Lorem Ipsum",
          submit_date: "2021/03/12",
          university: "RUP",
          institute: "XYZA",
          status: 2,
          reference_no: "1241",
        },
        {
          title: "T24 4Lorem Ipsum",
          submit_date: "2021/04/12",
          university: "UOP",
          institute: "XYYZ",
          status: 1,
          reference_no: "1242",
        },
        {
          title: "T42 Lorem Ipsum KitKat",
          submit_date: "2021/04/10",
          university: "OUSL",
          institute: "XXYY",
          status: 2,
          reference_no: "1243",
        },
      ];
    },
  },
  methods: {
    getCountryMOU() {},
    get_color(item) {
      if (item == 0) {
        return "#FFC107";
      }
      if (item == 1) {
        return "#4CAF50";
      }
      if (item == 2) {
        return "#2196F3";
      }
      if (item == 3) {
        return "#9C27B0";
      }
    },
    get_text(item) {
      if (item == 0) {
        return "AMENDMENTS";
      }
      if (item == 1) {
        return "APPROVED";
      }
      if (item == 2) {
        return "PROCEEDING";
      }
      if (item == 3) {
        return "IN MOHE";
      }
    },
    handleClick(item) {
      const reference_no = item.reference_no;
      this.$router.push({
        name: "mou",
        params: { id: reference_no },
      });
    },
  },
};
</script>

<style>
table td,
table th {
  vertical-align: middle !important;
}
</style>
