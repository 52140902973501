<template>
  <v-dialog v-model="formVisibility" persistent max-width="600px">
    <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
      <v-form :disabled="isFormDisabled">
        <v-card>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-toolbar-title>
              <span class="text-h6 sashika">CREATE USER</span></v-toolbar-title
            >
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    name="Name"
                    rules="required|alpha_spaces"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="name"
                      label="NAME"
                      prepend-inner-icon="mdi-account-outline"
                      dense
                      outlined
                      class="mx-2"
                      :success="valid"
                      :error-messages="errors[0]"
                      required
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    name="Username"
                    :rules="{
                      regex: /^[a-zA-Z0-9_]+$/,
                      min: 4,
                      required: true,
                    }"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="username"
                      label="USERNAME"
                      prepend-inner-icon="mdi-at"
                      dense
                      outlined
                      counter
                      class="mx-2"
                      :success="valid"
                      :error-messages="errors[0]"
                      required
                    ></v-text-field>
                    {{ valid }}
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    name="Email"
                    rules="required|email"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="email"
                      label="EMAIL"
                      prepend-inner-icon="mdi-email-fast-outline"
                      dense
                      outlined
                      class="mx-2"
                      :success="valid"
                      :error-messages="errors[0]"
                      required
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    name="Passwrod"
                    rules="required|min:4"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="password"
                      label="PASSWORD"
                      prepend-inner-icon="mdi-lock-outline"
                      dense
                      outlined
                      counter
                      class="mx-2 me"
                      :success="valid"
                      :error-messages="errors[0]"
                      required
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="5">
                  <validation-provider
                    name="User Type"
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-select
                      v-model="type"
                      :items="userTypes"
                      label="USER TYPE"
                      prepend-inner-icon="mdi-badge-account-outline"
                      dense
                      outlined
                      required
                      :success="valid"
                      :error-messages="errors[0]"
                      class="mx-2"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="7" v-if="instituteSelector">
                  <validation-provider
                    name="Institute"
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-autocomplete
                      :items="institutes"
                      v-model="institute"
                      :loading="getInstitutesApiActionStatus.isActive"
                      :error-messages="
                        getInstitutesApiActionStatus.message
                          ? getInstitutesApiActionStatus.message
                          : errors[0]
                      "
                      item-text="name"
                      item-value="_id"
                      label="INSTITUTE"
                      prepend-inner-icon="mdi-store-marker-outline"
                      dense
                      outlined
                      required
                      :success="valid"
                      class="mx-2"
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
            <v-alert
              prominent
              type="success"
              class="mx-2 my-2"
              :value="createUserApiActionStatus.status == 'success'"
            >
              <v-row align="center">
                <v-col class="grow">
                  User Added Successfully
                </v-col>
                <v-col class="shrink"> </v-col>
              </v-row>
            </v-alert>
            <v-alert
              prominent
              type="error"
              class="mx-2 my-2"
              :value="createUserApiActionStatus.status == 'failed'"
            >
              <v-row align="center">
                <v-col class="grow">
                  Action Failed {{ createUserApiActionStatus.message }}
                </v-col>
              </v-row>
            </v-alert>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              ripple
              depressed
              min-width="120"
              @click="clear"
            >
              CLOSE
            </v-btn>
            <v-btn
              v-if="showSubmitButton"
              color="blue darken-1"
              depressed
              ripple
              min-width="120"
              @click="handleSubmit(save)"
              :disabled="invalid"
            >
              SUBMIT
            </v-btn>
          </v-card-actions>

          <v-progress-linear
            :active="createUserApiActionStatus.isActive"
            :indeterminate="createUserApiActionStatus.isActive"
            absolute
            bottom
            height="6"
            color="blue accent-4"
          ></v-progress-linear>
        </v-card>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    name: "",
    username: null,
    email: "",
    password: "",
    institute: "",
    type: "USER",

    userTypes: ["ADMINISTRATOR", "USER"],
    isSubmitting: false,
    isFormDisabled: false,
    instituteSelector: true,
    showSubmitButton: true,
  }),
  watch: {
    type(value) {
      if (value == "ADMINISTRATOR") {
        this.instituteSelector = false;
      }
      if (value == "USER") {
        this.instituteSelector = true;
      }
    },
    createUserApiActionStatus(status) {
      if (status.status == "success") {
        this.isFormDisabled = true;
        this.showSubmitButton = false;
      }
      if (status.status == "failed") {
        this.isFormDisabled = false;
        this.showSubmitButton = true;
      }
    },
  },
  computed: {
    formVisibility() {
      return this.$store.getters["users/addUserDialogVisibility"];
    },

    institutes() {
      return this.$store.getters["institutes/institutes"];
    },

    authUser() {
      return this.$store.getters["auth/authUser"];
    },

    getInstitutesApiActionStatus() {
      const status = this.$store.getters["app/apiActionByName"](
        "getInstitutes"
      );

      if (status) {
        return status;
      }
      return {
        status: null,
        startTime: null,
        endTime: null,
        message: null,
        isActive: false,
        isRed: false,
      };
    },

    createUserApiActionStatus() {
      const status = this.$store.getters["app/apiActionByName"]("createUser");

      if (status) {
        return status;
      }
      return {
        status: null,
        startTime: null,
        endTime: null,
        message: null,
        isActive: false,
        isRed: false,
      };
    },
  },
  methods: {
    resetFormVariables() {
      this.name = "";
      this.username = "";
      this.email = "";
      this.password = "";
      this.institute = "";
      this.type = "USER";
      this.isSubmitting = false;
      this.isFormDisabled = false;
      this.instituteSelector = true;
      this.showSubmitButton = true;
    },

    resetValidationErrors() {
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },

    removeCreateUserApiAction() {
      this.$store.dispatch("app/removeApiAction", "createUser");
    },

    clear() {
      this.resetFormVariables();
      this.resetValidationErrors();
      this.removeCreateUserApiAction();
      this.$store.dispatch("users/setAddUserDialogVisibility", false);
    },

    save() {
      this.isFormDisabled = true;
      const user = {
        name: this.name,
        username: this.username,
        email: this.email,
        password: this.password,
        type: this.type,
        institute: this.institute,
        createdBy: this.authUser._id,
      };
      this.$store.dispatch("users/createUser", user);
    },

    loadInstitutes() {
      this.$store.dispatch("institutes/getInstitutes");
    },
    // async submit() {
    //   const isValid = await this.$refs.observer.validate();
    //   if (!isValid) {
    //     // ABORT!!
    //   }

    //   // 🐿 ship it
    // },
    loadData() {
      this.loadInstitutes();
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style scoped lang="css"></style>
