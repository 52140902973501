<template>
  <v-container class="">
    <v-row no-gutters>
      <v-col cols="12" md="12" class="pa-4">
        <country-table></country-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CountryTable from "@/components/countries/CountryTable.vue";

export default {
  components: {
    CountryTable,
  },
  data: () => ({}),
};
</script>

<style scoped>
.me-red {
  border: 1px solid #ebedf0;
}
</style>
