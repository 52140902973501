<template>
  <v-container class="">
    <v-row no-gutters>
      <v-app-bar color="white" prominent flat>
        <v-breadcrumbs :items="items"></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-app-bar>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="6">
        <v-row no-gutters class="pa-4">
          <v-col cols="12" md="6">
            <v-card class="mx-auto my-6" max-width="280" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 amber--text" color="red">
                    AMENDMENTS
                  </div>
                  <v-list-item-title class="headline mb-1">
                    12
                  </v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                  tile
                  size="80"
                  color="grey"
                ></v-list-item-avatar>
              </v-list-item>

              <v-card-actions> </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="mx-auto my-6" max-width="280" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 green--text">
                    APPROVED
                  </div>
                  <v-list-item-title class="headline mb-1">
                    4
                  </v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                  tile
                  size="80"
                  color="grey"
                ></v-list-item-avatar>
              </v-list-item>

              <v-card-actions> </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="mx-auto my-6" max-width="280" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 light-blue--text">
                    PROCEEDING
                  </div>
                  <v-list-item-title class="headline mb-1">
                    2
                  </v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                  tile
                  size="80"
                  color="grey"
                ></v-list-item-avatar>
              </v-list-item>

              <v-card-actions> </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="mx-auto my-6" max-width="280" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 purple--text">
                    IN MOHE
                  </div>
                  <v-list-item-title class="headline mb-1">
                    4
                  </v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                  tile
                  size="80"
                  color="grey"
                ></v-list-item-avatar>
              </v-list-item>

              <v-card-actions> </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <university-pie-chart></university-pie-chart>
      </v-col>

      <v-col cols="12" md="6" class="pa-4">
        <university-mou-table></university-mou-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MouTimeline from "@/components/mous/MouTimeline.vue";
import UniversityMouTable from "@/components/universities/UniversityMouTable.vue";
import UniversityPieChart from "@/components/universities/UniversityPieChart.vue";

export default {
  components: {
    UniversityMouTable,
    MouTimeline,
    UniversityPieChart,
  },
  props: ["id"],
  data: () => ({
    labels: ["SU", "MO", "TU", "WED", "TH", "FR", "SA"],
    time: 0,
    forecast: [
      {
        day: "Tuesday",
        icon: "mdi-white-balance-sunny",
        temp: "24\xB0/12\xB0",
      },
      {
        day: "Wednesday",
        icon: "mdi-white-balance-sunny",
        temp: "22\xB0/14\xB0",
      },
      { day: "Thursday", icon: "mdi-cloud", temp: "25\xB0/15\xB0" },
    ],
    items: [
      {
        text: "DASHBOARD",
        disabled: false,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "MOU",
        disabled: false,
        href: "breadcrumbs_link_1",
      },
      {
        text: "",
        disabled: true,
        href: "breadcrumbs_link_2",
      },
    ],
  }),
};
</script>

<style>
.me-red {
  border: 1px solid #ebedf0;
}

.no-wrap {
  text-overflow: unset;
  white-space: normal;
}
</style>
