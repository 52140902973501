<template>
  <v-container class="">
    <v-row no-gutters>
      <v-app-bar color="white" prominent flat>
        <v-breadcrumbs :items="items"></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-app-bar>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="6">
        <v-card flat>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline no-wrap">
                {{ mou.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="no-wrap">
                <span class="mr-4">
                  <v-icon size="18px">mdi-calendar-month</v-icon>
                </span>
                <v-chip class="ma-2" color="blue" label outlined small>
                  {{ moment(mou.createdAt).format("YYYY-MM-DD") }}
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="no-wrap">
                <span class="mr-4">
                  <v-icon size="18px">mdi-timer-outline</v-icon>
                </span>
                <v-chip class="ma-2" color="blue" label outlined small>
                  {{ moment(mou.createdAt).format("hh:mm A") }}
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <span class="mr-4">
              <v-icon size="18px">mdi-account-plus-outline</v-icon>
            </span>
            <v-list-item-content>
              <v-list-item-title class="no-wrap">
                <v-chip class="ma-2" color="blue" label outlined small>
                  {{ mou.createdBy.name }}
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <span class="mr-4">
              <v-icon size="18px">mdi-store-cog-outline</v-icon>
            </span>
            <v-list-item-content>
              <v-list-item-title class="no-wrap">
                <v-chip class="ma-2" color="green" label outlined small>
                  {{ mou.institute.name }}
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list class="transparent">
            <v-list-item>
              <figure class="pa-1 image is-128x128">
                <img src="https://bulma.io/images/placeholders/128x128.png" />
              </figure>
              <figure class="pa-1 image is-128x128">
                <img src="https://bulma.io/images/placeholders/128x128.png" />
              </figure>
              <figure class="pa-1 image is-128x128">
                <img src="https://bulma.io/images/placeholders/128x128.png" />
              </figure>
            </v-list-item>
          </v-list>
        </v-card>
        <button
          class="button is-primary is-fullwidth mt-4"
          @click="showAddEventDialog()"
        >
          <span class="icon">
            <i class="mdi mdi-plus"></i>
          </span>
          <span>ADD EVENT</span>
        </button>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="pa-4" flat>
          <mou-timeline :id="id"></mou-timeline>
        </v-card>
      </v-col>
    </v-row>
    <add-event-dialog></add-event-dialog>
  </v-container>
</template>

<script>
import MouTable from "@/components/mous/MouTable.vue";
import MouTimeline from "@/components/mous/MouTimeline.vue";
import AddEventDialog from "@/components/mous/AddEventDialog.vue";
import moment from "moment";

export default {
  components: {
    MouTable,
    MouTimeline,
    AddEventDialog,
  },
  props: ["id"],
  data: function() {
    return {
      items: [
        {
          text: "DASHBOARD",
          disabled: false,
          href: "/",
        },
        {
          text: "MOUs",
          disabled: false,
          href: "/mous",
        },
        {
          text: this.id,
          disabled: true,
          href: "",
        },
      ],
    };
  },
  computed: {
    mou() {
      return this.$store.getters["mous/mou"];
    },
  },
  methods: {
    showAddEventDialog() {
      this.$store.dispatch("mous/setAddEventDialogVisibility", true);
    },

    moment: function(date) {
      return moment(date);
    },

    numberOfDays: function(firstDate, secondDate = Date.now()) {
      const oneDay = 24 * 60 * 60 * 1000;
      const _firstDate = new Date(firstDate);
      const _secondDate = new Date(secondDate);

      return Math.round(Math.abs((_firstDate - _secondDate) / oneDay));
    },

    date: function(firstDate, secondDate = Date.now()) {
      const oneDay = 24 * 60 * 60 * 1000;
      const _firstDate = new Date(firstDate);
      const _secondDate = new Date(secondDate);

      return Math.round(Math.abs((_firstDate - _secondDate) / oneDay));
    },

    time: function(dateTime) {
      const time = new Date(dateTime);
      return time.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    },

    dateTime: function(dateTime) {
      return this.date(dateTime) + " " + this.time(dateTime);
    },
    getMou() {
      this.$store.dispatch("mous/getMouById", this.id);
    },
  },
  mounted() {
    this.getMou();
  },
};
</script>

<style>
.me-red {
  border: 1px solid #ebedf0;
}

.no-wrap {
  text-overflow: unset;
  white-space: normal;
}
</style>
