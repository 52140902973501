<template>
  <v-container class="">
    <v-row no-gutters>
      <v-col cols="12" md="12" class="pa-4">
        <approver-table></approver-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApproverTable from "@/components/approvers/ApproverTable.vue";

export default {
  components: {
    ApproverTable,
  },
  data: () => ({}),
};
</script>

<style scoped>
.me-red {
  border: 1px solid #ebedf0;
}
</style>
