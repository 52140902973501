<template>
  <v-container class="me-red">
    <v-breadcrumbs :items="items"></v-breadcrumbs>
    <v-row class="text-center">
      <university-table></university-table>
    </v-row>
  </v-container>
</template>

<script>
import UniversityTable from "@/components/universities/UniversityTable.vue";

export default {
  components: {
    UniversityTable,
  },
  data: () => ({
    items: [
      {
        text: "DASHBOARD",
        disabled: false,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "UNIVERSITY",
        disabled: false,
        href: "breadcrumbs_link_1",
      },
      {
        text: "LIST",
        disabled: true,
        href: "breadcrumbs_link_2",
      },
    ],
  }),
};
</script>

<style scoped>
.me-red {
  border: 1px solid #ebedf0;
}
</style>
