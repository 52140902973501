<template>
  <v-dialog v-model="formVisibility" persistent max-width="600px">
    <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
      <v-card>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <span class="text-h6">ADD EVENT</span></v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <validation-provider
                  name="EventType"
                  rules="required"
                  v-slot="{ errors, valid }"
                >
                  <v-autocomplete
                    :items="eventTypes"
                    v-model="eventType"
                    :loading="getInstitutesApiActionStatus.isActive"
                    :error-messages="errors[0]"
                    item-text="name"
                    item-value="_id"
                    label="EVENT TYPE"
                    prepend-inner-icon="mdi-store-marker-outline"
                    dense
                    outlined
                    required
                    :success="valid"
                    class="mx-2"
                  ></v-autocomplete>
                </validation-provider>
              </v-col>
              <template v-if="eventType == 'SEND'">
                <v-col cols="6">
                  <validation-provider
                    name="Institute"
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-autocomplete
                      :items="institutes"
                      v-model="sendTo"
                      :loading="getInstitutesApiActionStatus.isActive"
                      :error-messages="
                        getInstitutesApiActionStatus.message
                          ? getInstitutesApiActionStatus.message
                          : errors[0]
                      "
                      item-text="name"
                      item-value="_id"
                      label="SEND TO"
                      prepend-inner-icon="mdi-store-marker-outline"
                      dense
                      outlined
                      required
                      :success="valid"
                      class="mx-2"
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider
                    name="SendFor"
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-autocomplete
                      :items="sendForTypes"
                      v-model="sendFor"
                      :error-messages="errors[0]"
                      label="SEND FOR"
                      prepend-inner-icon="mdi-store-marker-outline"
                      dense
                      outlined
                      required
                      :success="valid"
                      class="mx-2"
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
              </template>
              <template v-if="eventType == 'RECEIVE'">
                <v-col cols="6">
                  <validation-provider
                    name="Institute"
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-autocomplete
                      :items="institutes"
                      v-model="receiveFrom"
                      :loading="getInstitutesApiActionStatus.isActive"
                      :error-messages="
                        getInstitutesApiActionStatus.message
                          ? getInstitutesApiActionStatus.message
                          : errors[0]
                      "
                      item-text="name"
                      item-value="_id"
                      label="RECEIVE FROM"
                      prepend-inner-icon="mdi-store-marker-outline"
                      dense
                      outlined
                      required
                      :success="valid"
                      class="mx-2"
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider
                    name="ReceiveFor"
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-autocomplete
                      :items="receiveForTypes"
                      v-model="receiveFor"
                      :error-messages="errors[0]"
                      label="RECEIVE FOR"
                      prepend-inner-icon="mdi-store-marker-outline"
                      dense
                      outlined
                      required
                      :success="valid"
                      class="mx-2"
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
              </template>
              <v-col cols="12">
                <v-textarea
                  v-model="comment"
                  outlined
                  label="COMMENTS"
                  prepend-inner-icon="mdi-account-outline"
                  rows="1"
                  class="mx-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <v-alert
            prominent
            type="success"
            class="mx-2 my-2"
            :value="addEventApiActionStatus.status == 'success'"
          >
            <v-row align="center">
              <v-col class="grow">
                Mou Added Successfully
              </v-col>
              <v-col class="shrink"> </v-col>
            </v-row>
          </v-alert>
          <v-alert
            prominent
            type="error"
            class="mx-2 my-2"
            :value="addEventApiActionStatus.status == 'failed'"
          >
            <v-row align="center">
              <v-col class="grow">
                Action Failed {{ addEventApiActionStatus.message }}
              </v-col>
            </v-row>
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            ripple
            depressed
            min-width="120"
            @click="close()"
          >
            CLOSE
          </v-btn>
          <v-btn
            v-if="showSubmitButton"
            color="blue darken-1"
            depressed
            ripple
            min-width="120"
            @click="handleSubmit(save)"
            :disabled="invalid"
          >
            SUBMIT
          </v-btn>
        </v-card-actions>

        <v-progress-linear
          :active="addEventApiActionStatus.isActive"
          :indeterminate="addEventApiActionStatus.isActive"
          absolute
          bottom
          height="6"
          color="blue accent-4"
        ></v-progress-linear>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    eventType: "SEND",
    institute: "",

    receiveFor: null,
    receiveTo: null,
    receiveFrom: null,
    receiveForTypes: ["APPROVED", "AMENDMENTS"],

    sendFor: null,
    sendTo: null,
    sendFrom: null,
    sendForTypes: ["APPROVAL", "AMENDMENTS"],

    comment: null,

    eventTypes: ["SEND", "RECEIVE"],

    code: "",
    title: "",
    createdBy: "",
    comment: "",

    isSubmitting: false,
    isFormDisabled: false,
    showSubmitButton: true,
  }),
  watch: {
    addEventApiActionStatus(status) {
      if (status.status == "success") {
        this.isFormDisabled = true;
        this.showSubmitButton = false;
      }
      if (status.status == "failed") {
        this.isFormDisabled = false;
        this.showSubmitButton = true;
      }
    },
  },
  computed: {
    mouId() {
      return this.$store.getters["mous/mou"]._id;
    },
    formVisibility() {
      return this.$store.getters["mous/addEventDialogVisibility"];
    },

    institutes() {
      return this.$store.getters["institutes/institutes"];
    },

    authUser() {
      return this.$store.getters["auth/authUser"];
    },

    getInstitutesApiActionStatus() {
      const status = this.$store.getters["app/apiActionByName"](
        "getInstitutes"
      );

      if (status) {
        return status;
      }
      return {
        status: null,
        startTime: null,
        endTime: null,
        message: null,
        isActive: false,
        isRed: false,
      };
    },

    addEventApiActionStatus() {
      const status = this.$store.getters["app/apiActionByName"]("addEvent");

      if (status) {
        return status;
      }
      return {
        status: null,
        startTime: null,
        endTime: null,
        message: null,
        isActive: false,
        isRed: false,
      };
    },
  },
  methods: {
    resetFormVariables() {
      this.eventType = "SEND";
      this.institute = "";

      this.receiveFor = null;
      this.receiveTo = null;
      this.receiveFrom = null;
      this.receiveForTypes = ["APPROVED", "AMENDMENTS"];

      this.sendFor = null;
      this.sendTo = null;
      this.sendFrom = null;
      this.sendForTypes = ["APPROVAL", "AMENDMENTS"];

      this.comment = null;

      this.eventTypes = ["SEND", "RECEIVE"];

      this.code = "";
      this.title = "";
      this.createdBy = "";
      this.comment = "";

      this.isSubmitting = false;
      this.isFormDisabled = false;
      this.showSubmitButton = true;
    },

    resetValidationErrors() {
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },

    removeAddEventApiAction() {
      this.$store.dispatch("app/removeApiAction", "addEvent");
    },

    close() {
      this.resetFormVariables();
      this.resetValidationErrors();
      this.removeAddEventApiAction();
      this.$store.dispatch("mous/setAddEventDialogVisibility", false);
    },

    save() {
      this.isFormDisabled = true;
      const event = {
        mouId: this.mouId,
        eventType: this.eventType,

        receiveFor: this.receiveFor,
        receiveTo: this.receiveTo,
        receiveFrom: this.receiveFrom,

        sendFor: this.sendFor,
        sendTo: this.sendTo,
        sendFrom: this.sendFrom,

        comment: this.comment,
        createdBy: this.authUser._id,
      };

      this.$store.dispatch("mous/addEvent", event);
    },

    loadInstitutes() {
      this.$store.dispatch("institutes/getInstitutes");
    },

    loadData() {
      this.loadInstitutes();
    },
  },
  created() {
    this.loadData();
  },
};
</script>
