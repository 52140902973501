<template>
  <v-container class="">
    <v-row no-gutters>
      <v-col cols="12" md="7" class="px-1 pt-2">
        <v-row no-gutters>
          <v-col cols="12" md="12">
            <v-card class="mx-auto my-2" max-width="280" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 green--text">
                    TOTAL
                  </div>
                  <v-list-item-title class="headline mb-1">
                    {{ total }}
                  </v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions> </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <v-row no-gutters class="pb-6">
          <v-col cols="12" lg="3">
            <v-card class="mx-auto my-6" max-width="160" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 green--text">
                    APPROVED
                  </div>
                  <v-list-item-title class="headline mb-1">
                    {{ approved }}
                  </v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions> </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" lg="3">
            <v-card class="mx-auto my-6 pa" max-width="160" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 amber--text" color="red">
                    AMENDMENTS
                  </div>
                  <v-list-item-title class="headline mb-1">
                    {{ ammendments }}
                  </v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions> </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" lg="3">
            <v-card class="mx-auto my-6" max-width="160" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 light-blue--text">
                    PROCEEDING
                  </div>
                  <v-list-item-title class="headline mb-1">
                    {{ proceeding }}
                  </v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions> </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" lg="3">
            <v-card class="mx-auto my-6" max-width="160" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 purple--text">
                    IN MOHE
                  </div>
                  <v-list-item-title class="headline mb-1">
                    {{ inMOHE }}
                  </v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions> </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <mou-pie-chart></mou-pie-chart>
        <active-country-bar-chart></active-country-bar-chart>
      </v-col>

      <v-col cols="12" md="5" class="px-1 pt-2">
        <h6 class="text-center font-weight-bold">
          <div class="pa-2">
            <v-btn block tile color="info" @click="showAddMouDialog()">
              ADD MOU
            </v-btn>
          </div>
        </h6>
        <categorized-mou-table></categorized-mou-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MouPieChart from "@/components/mous/MouPieChart.vue";
import ActiveCountryBarChart from "@/components/mous/ActiveCountryBarChart.vue";
import CategorizedMouTable from "@/components/mous/CategorizedMouTable.vue";
export default {
  components: {
    MouPieChart,
    ActiveCountryBarChart,
    CategorizedMouTable,
  },
  data: () => ({}),
  computed: {
    ammendments() {
      return 10;
    },

    proceeding() {
      return 14;
    },

    inMOHE() {
      return 20;
    },

    approved() {
      return 24;
    },
    total() {
      return this.ammendments + this.proceeding + this.inMOHE + this.approved;
    },
  },
  methods: {
    showAddMouDialog() {
      this.$store.dispatch("mous/setAddMouDialogVisibility", true);
    },
  },
};
</script>

<style scoped>
.me-red {
  border: 1px solid #ebedf0;
}
</style>
