<template>
  <v-card flat class="border mx-auto">
    <v-card flat>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        class="ma-6"
      ></v-text-field>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :search="search"
      class="v-align"
      @click:row="handleClick"
    >
      <template v-slot:item.status="{ item }">
        <v-btn icon color="blue">
          <v-icon size="20px">mdi-open-in-new</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.grants="{ item }">
        <country-flag :country="item.country" size="normal" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import CountryFlag from "vue-country-flag";

export default {
  components: {
    CountryFlag,
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "NAME", value: "title" },
        { text: "CODE", value: "university" },
        { text: "MOUS", value: "mous" },
        { text: "GRANTS", value: "grants" },
        { text: "ACTION", align: "center", value: "status" },
      ],
      desserts: [
        {
          id: 1,
          title: "University of Colombo",
          submit_date: "2021/10/12",
          university: "UOC",
          reference_no: "1",
          mous: 20,
          grants: 8,
          country: "lk",
        },
        {
          id: 2,
          title: "University of Peradeniya",
          submit_date: "2021/01/12",
          university: "UOP",
          reference_no: "2",
          mous: 12,
          grants: 22,
          country: "gb",
        },
        {
          id: 3,
          title: "University of Moratuwa",
          submit_date: "2021/02/12",
          university: "UOM",
          reference_no: "3",
          mous: 31,
          grants: 14,
          country: "ag",
        },
        {
          id: 4,
          title: "University of Ruhuna",
          submit_date: "2021/02/12",
          university: "UOR",
          reference_no: "4",
          mous: 52,
          grants: 32,
          country: "in",
        },
        {
          id: 5,
          title: "University of Sri Jayawardhanapura",
          submit_date: "2021/03/12",
          university: "USJ",
          reference_no: "5",
          mous: 43,
          grants: 24,
          country: "fr",
        },
      ],
      message: "",
    };
  },
  methods: {
    handleClick(item) {
      this.$router.push({
        name: "university",
        params: { id: item.id },
      });
    },
  },
};
</script>

<style>
table td,
table th {
  vertical-align: middle !important;
}
</style>
