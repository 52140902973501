<template>
  <v-row justify="center">
    <v-dialog v-model="visibility" max-width="600px">
      <v-card>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <span class="text-h6">CREATE APPROVER</span></v-toolbar-title
          >
          <v-progress-linear
            :active="isApiRequestActive"
            :indeterminate="isApiRequestActive"
            absolute
            top
            height="6"
            color="deep-purple accent-4"
          ></v-progress-linear>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="code"
                  label="Code"
                  single-line
                  hide-details
                  dense
                  outlined
                  class="mx-2 my-2 mt-4"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  label="Title"
                  single-line
                  hide-details
                  dense
                  outlined
                  class="mx-2 my-2"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-alert
            prominent
            type="success"
            class="mx-2 my-2"
            :value="isApiRequestSuccess"
          >
            <v-row align="center">
              <v-col class="grow">
                Approver Added Successfully
              </v-col>
              <v-col class="shrink"> </v-col>
            </v-row>
          </v-alert>
          <v-alert
            prominent
            type="error"
            class="mx-2 my-2"
            :value="isApiRequestFailed"
          >
            <v-row align="center">
              <v-col class="grow"> Action Failed {{ apiActionError }} </v-col>
              <v-col class="shrink">
                <v-btn @click="reenter()">RE ENTER</v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel()">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save()"
            :disabled="!isFormActive"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    isFormEnable: true,
    loading: true,
    code: "",
    name: "",
  }),
  computed: {
    visibility() {
      return this.$store.getters["approvers/addApproverDialogVisibility"];
    },

    authUser() {
      return this.$store.getters["auth/authUser"];
    },

    isApiRequestActive() {
      if (!this.apiActionStatus) {
        return false;
      }

      if (this.apiActionStatus["isActive"] == true) {
        return true;
      }
      return false;
    },

    isApiRequestFailed() {
      if (!this.apiActionStatus) {
        return false;
      }

      if (this.apiActionStatus["isRed"]) {
        return false;
      }

      if (this.apiActionStatus["status"] == "failed") {
        return true;
      }
      return false;
    },

    isApiRequestSuccess() {
      if (!this.apiActionStatus) {
        return false;
      }

      if (this.apiActionStatus["isRed"]) {
        return false;
      }

      if (this.apiActionStatus["status"] == "success") {
        return true;
      }

      return false;
    },

    apiActionStatus() {
      if (!this.$store.getters["app/apiActionStatus"]["createApprover"]) {
        return null;
      }
      if (
        this.$store.getters["app/apiActionStatus"]["createApprover"]["isRed"]
      ) {
        return null;
      }
      return this.$store.getters["app/apiActionStatus"]["createApprover"];
    },

    isFormActive() {
      if (!this.apiActionStatus) {
        return true;
      }

      if (this.apiActionStatus["isRed"]) {
        return true;
      }

      return false;
    },

    apiActionError() {
      if (!this.apiActionStatus) {
        return "";
      }
      return this.apiActionStatus["meessage"];
    },
  },
  methods: {
    save() {
      const institute = {
        code: this.code,
        name: this.name,
        createdBy: this.authUser._id,
      };
      this.$store.dispatch("approvers/createApprover", institute);
    },

    cancel() {
      this.refreshForm();
      this.$store.dispatch("approvers/setAddApproverDialogVisibility", false);
    },

    resetForm() {
      this.code = "";
      this.name = "";
    },

    setApiActionStatusRed() {
      if (this.apiActionStatus) {
        this.$store.dispatch("app/setApiActionStatusRed", "createApprover");
      }
    },

    reenter() {
      this.save();
      this.setApiActionStatusRed();
    },

    refreshForm() {
      this.resetForm();
      this.setApiActionStatusRed();
    },
  },
};
</script>
