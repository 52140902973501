<template>
  <v-container class="">
    <v-row no-gutters>
      <v-app-bar color="white" prominent flat>
        <v-breadcrumbs :items="items"></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-app-bar>
    </v-row>

    <v-container class="grey lighten-5">
      <v-row no-gutters>
        <v-col cols="4">
          <v-card tile flat>
            <v-list-item>
              <v-list-item-content class="text-center">
                <v-list-item-title class="title mt-0">
                  <!-- <institute-mou-pie-chart
                    class="mt-6"
                  ></institute-mou-pie-chart> -->
                  <v-avatar size="78">
                    <img :src="require('@/assets/avatar.png')" alt="John" />
                  </v-avatar>
                </v-list-item-title>
                <v-list-item-subtitle class="my-2">
                  <div>{{ instituteName.toUpperCase() }}</div>
                  <!-- <div class="mt-2">{{ user.email }}</div> -->
                  <!-- <div class="mt-2">{{ ddmmyy(user.createdAt) }}</div> -->
                </v-list-item-subtitle>
                <v-list-item-subtitle class="">
                  <v-chip class="ma-1" color="success" outlined label>
                    <v-icon left>
                      mdi-server-plus
                    </v-icon>
                    <!-- {{ user.type.toUpperCase() }} -->
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-container center class="" fill-height>
            <v-col cols="12" md="3">
              <v-card class="mx-auto" max-width="280" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="overline mb-4 blue--text">
                      PROCEEDING
                    </div>
                    <v-list-item-title class="headline mb-1">
                      12
                    </v-list-item-title>
                    <v-list-item-subtitle> </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="12" md="3">
              <v-card class="mx-auto" max-width="280" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="overline mb-4 amber--text">
                      IN MOHE
                    </div>
                    <v-list-item-title class="headline mb-1">
                      12
                    </v-list-item-title>
                    <v-list-item-subtitle></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="12" md="3">
              <v-card class="mx-auto" max-width="280" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="overline mb-4 amber--text" color="red">
                      AMENDMENTS
                    </div>
                    <v-list-item-title class="headline mb-1">
                      12
                    </v-list-item-title>
                    <v-list-item-subtitle></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="12" md="3">
              <v-card class="mx-auto" max-width="280" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="overline mb-4 black--text" color="red">
                      TOTAL
                    </div>
                    <v-list-item-title class="headline mb-1">
                      12
                    </v-list-item-title>
                    <v-list-item-subtitle></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <v-row no-gutters>
      <v-col cols="12" md="4">
        <institute-mou-pie-chart class="mt-6"></institute-mou-pie-chart>
      </v-col>

      <v-col cols="12" md="8" class="pa-4">
        <institute-mou-table id="sashik"></institute-mou-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InstituteMouTable from "@/components/institutes/InstituteMouTable.vue";
import InstituteMouPieChart from "@/components/institutes/InstitutePieChart.vue";

import { time } from "echarts/core";

export default {
  components: {
    InstituteMouPieChart,
    InstituteMouTable,
  },
  props: ["id"],
  data: function() {
    return {
      time: 0,
      items: [
        {
          text: "DASHBOARD",
          disabled: false,
          href: "/",
        },
        {
          text: "INSTITUTES",
          disabled: false,
          href: "/institutes",
        },
        {
          text: this.id,
          disabled: true,
          href: "",
        },
      ],
    };
  },
  computed: {
    institute() {
      const institutes = this.$store.getters["institutes/institutes"];
      return institutes.find((el) => el._id === this.id);
    },
    instituteName() {
      if ((this.institute == null) | (this.institute == undefined)) {
        return "";
      }
      return this.institute.name;
    },
  },
  methods: {
    ddmmyy: function(timestamp) {
      if (timestamp) {
        return new Date(timestamp)
          .toISOString()
          .replace(/T.*/, "")
          .split("-")
          .reverse()
          .join("-");
      }

      return "";
    },

    // getInstituteUser() {
    //   this.$store.dispatch("users/getUserById", this.id);
    // },
    loadInstituteMous() {
      this.$store.dispatch("mous/getMousByInstituteId", this.id);
    },
  },
  mounted() {
    // this.getUser();
    this.loadInstituteMous();
  },
};
</script>

<style></style>
