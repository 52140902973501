<template>
  <h1>
    <v-chart class="chart" :option="option" />
  </h1>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: "niversitymouchart",
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  data() {
    return {
      option: {
        legend: {},
        tooltip: {},
        dataset: {
          source: [
            ["STATUS", "PENDING", "AMENDMENTS", "APROVED"],
            ["Ministry Of Education", 12, 10, 2],
            ["Ministry Of Health", 10, 3, 2],
            ["Ministry Of Home Affairs", 1, 1, 2],
            ["Ministry Of Defense", 7, 4, 2],
            ["Cabinet", 4, 4, 2],
          ],
        },
        xAxis: { type: "category" },
        yAxis: {},

        series: [{ type: "bar" }, { type: "bar" }, { type: "bar" }],
      },
    };
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
