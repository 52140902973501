import { render, staticRenderFns } from "./Approvers.vue?vue&type=template&id=530e8b8e&scoped=true&"
import script from "./Approvers.vue?vue&type=script&lang=js&"
export * from "./Approvers.vue?vue&type=script&lang=js&"
import style0 from "./Approvers.vue?vue&type=style&index=0&id=530e8b8e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "530e8b8e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VAppBar,VBreadcrumbs,VBtn,VContainer,VIcon,VRow,VSpacer,VTab,VTabItem,VTabs})
