<template>
  <v-container class="">
    <v-row no-gutters>
      <v-col cols="12" md="12" class="pa-4">
        <mou-table></mou-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MouTable from "@/components/mous/MouTable.vue";

export default {
  components: {
    MouTable,
  },
  data: () => ({}),
};
</script>

<style scoped>
.me-red {
  border: 1px solid #ebedf0;
}
</style>
