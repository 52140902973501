<template>
  <v-dialog v-model="formVisibility" persistent max-width="600px">
    <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
      <v-card>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <span class="text-h6">ADD MOU</span></v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <validation-provider
                  name="Code"
                  rules="required"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="code"
                    label="CODE"
                    prepend-inner-icon="mdi-account-outline"
                    dense
                    outlined
                    class="mx-2"
                    :success="valid"
                    :error-messages="errors[0]"
                    required
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12">
                <validation-provider
                  name="Title"
                  rules="required"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="title"
                    label="TITLE"
                    prepend-inner-icon="mdi-account-outline"
                    dense
                    outlined
                    class="mx-2"
                    :success="valid"
                    :error-messages="errors[0]"
                    required
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col cols="7">
                <validation-provider
                  name="Institute"
                  rules="required"
                  v-slot="{ errors, valid }"
                >
                  <v-autocomplete
                    :items="institutes"
                    v-model="institute"
                    :loading="getInstitutesApiActionStatus.isActive"
                    :error-messages="
                      getInstitutesApiActionStatus.message
                        ? getInstitutesApiActionStatus.message
                        : errors[0]
                    "
                    item-text="name"
                    item-value="_id"
                    label="INSTITUTE"
                    prepend-inner-icon="mdi-store-marker-outline"
                    dense
                    outlined
                    required
                    :success="valid"
                    class="mx-2"
                  ></v-autocomplete>
                </validation-provider>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  v-model="comment"
                  outlined
                  label="COMMENTS"
                  prepend-inner-icon="mdi-account-outline"
                  rows="1"
                  class="mx-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <v-alert
            prominent
            type="success"
            class="mx-2 my-2"
            :value="createMouApiActionStatus.status == 'success'"
          >
            <v-row align="center">
              <v-col class="grow">
                Mou Added Successfully
              </v-col>
              <v-col class="shrink"> </v-col>
            </v-row>
          </v-alert>
          <v-alert
            prominent
            type="error"
            class="mx-2 my-2"
            :value="createMouApiActionStatus.status == 'failed'"
          >
            <v-row align="center">
              <v-col class="grow">
                Action Failed {{ createMouApiActionStatus.message }}
              </v-col>
            </v-row>
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" ripple depressed min-width="120" @click="clear">
            CLOSE
          </v-btn>
          <v-btn
            v-if="showSubmitButton"
            color="blue darken-1"
            depressed
            ripple
            min-width="120"
            @click="handleSubmit(save)"
            :disabled="invalid"
          >
            SUBMIT
          </v-btn>
        </v-card-actions>

        <v-progress-linear
          :active="createMouApiActionStatus.isActive"
          :indeterminate="createMouApiActionStatus.isActive"
          absolute
          bottom
          height="6"
          color="blue accent-4"
        ></v-progress-linear>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    code: "",
    title: "",
    institute: "",
    createdBy: "",
    comment: "",

    isSubmitting: false,
    isFormDisabled: false,
    showSubmitButton: true,
  }),
  watch: {
    createMouApiActionStatus(status) {
      if (status.status == "success") {
        this.isFormDisabled = true;
        this.showSubmitButton = false;
      }
      if (status.status == "failed") {
        this.isFormDisabled = false;
        this.showSubmitButton = true;
      }
    },
  },
  computed: {
    formVisibility() {
      return this.$store.getters["mous/addMouDialogVisibility"];
    },

    institutes() {
      return this.$store.getters["institutes/institutes"];
    },

    authUser() {
      return this.$store.getters["auth/authUser"];
    },

    getInstitutesApiActionStatus() {
      const status = this.$store.getters["app/apiActionByName"](
        "getInstitutes"
      );

      if (status) {
        return status;
      }
      return {
        status: null,
        startTime: null,
        endTime: null,
        message: null,
        isActive: false,
        isRed: false,
      };
    },

    createMouApiActionStatus() {
      const status = this.$store.getters["app/apiActionByName"]("createMou");

      if (status) {
        return status;
      }
      return {
        status: null,
        startTime: null,
        endTime: null,
        message: null,
        isActive: false,
        isRed: false,
      };
    },
  },
  methods: {
    resetFormVariables() {
      this.code = "";
      this.title = "";
      this.institute = "";
      this.comment = "";

      this.isSubmitting = false;
      this.isFormDisabled = false;
      this.instituteSelector = true;
      this.showSubmitButton = true;
    },

    resetValidationErrors() {
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },

    removeCreateMouApiAction() {
      this.$store.dispatch("app/removeApiAction", "createMou");
    },

    clear() {
      this.resetFormVariables();
      this.resetValidationErrors();
      this.removeCreateMouApiAction();
      this.$store.dispatch("mous/setAddMouDialogVisibility", false);
    },

    save() {
      this.isFormDisabled = true;
      const mou = {
        code: this.code,
        title: this.title,
        institute: this.institute,
        createdBy: this.authUser._id,
        comment: this.comment,
      };
      this.$store.dispatch("mous/createMou", mou);
    },

    loadInstitutes() {
      this.$store.dispatch("institutes/getInstitutes");
    },

    loadData() {
      this.loadInstitutes();
    },
  },
  created() {
    this.loadData();
  },
};
</script>
