<template>
  <v-container class="">
    <v-row no-gutters>
      <v-col cols="12" md="6" class="px-1 py-4">
        <v-card flat class="mt-6 mb-6">
          <v-tabs color="blue accent-4" centered>
            <v-tab>OVERALL</v-tab>
            <v-tab>APPROVED</v-tab>
            <v-tab>AMENDMENTS</v-tab>
            <v-tab>PROCEEDING</v-tab>
            <v-tab>IN MOHE</v-tab>

            <v-tab-item>
              <total-mous-pie-chart></total-mous-pie-chart>
            </v-tab-item>
            <v-tab-item>
              <approved-mous-pie-chart></approved-mous-pie-chart>
            </v-tab-item>
            <v-tab-item>
              <amendment-mous-pie-chart></amendment-mous-pie-chart>
            </v-tab-item>
            <v-tab-item>
              <proceeding-mous-pie-chart></proceeding-mous-pie-chart>
            </v-tab-item>
            <v-tab-item>
              <in-mohe-mous-pie-chart></in-mohe-mous-pie-chart>
            </v-tab-item>
          </v-tabs>
        </v-card>
        <v-card flat class="my-6 pt-6">
          <institute-bar-chart></institute-bar-chart>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" class="px-1 pt-2">
        <institute-stat-table></institute-stat-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TotalMousPieChart from "@/components/institutes/TotalMousPieChart.vue";
import ApprovedMousPieChart from "@/components/institutes/ApprovedMousPieChart.vue";
import AmendmentMousPieChart from "@/components/institutes/AmendmentMousPieChart.vue";
import ProceedingMousPieChart from "@/components/institutes/ProceedingMousPieChart.vue";
import InMoheMousPieChart from "@/components/institutes/InMoheMousPieChart.vue";

import InstituteBarChart from "@/components/institutes/InstituteBarChart.vue";
import InstituteStatTable from "@/components/institutes/InstituteStatTable.vue";

export default {
  components: {
    TotalMousPieChart,
    ApprovedMousPieChart,
    AmendmentMousPieChart,
    ProceedingMousPieChart,
    InMoheMousPieChart,
    InstituteBarChart,
    InstituteStatTable,
  },
  data: () => ({}),
};
</script>

<style scoped>
.me-red {
  border: 1px solid #ebedf0;
}
</style>
