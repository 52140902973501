<template>
  <v-container class="me-red">
    <v-breadcrumbs :items="items"></v-breadcrumbs>
    <v-row class="text-center">
      <user-table></user-table>
    </v-row>
    <add-user-dialog></add-user-dialog>
  </v-container>
</template>

<script>
import UserTable from "@/components/users/UserTable.vue";
import AddUserDialog from "@/components/users/AddUserDialog.vue";

export default {
  components: {
    UserTable,
    AddUserDialog,
  },
  data: () => ({
    items: [
      {
        text: "DASHBOARD",
        disabled: false,
        href: "/",
      },
      {
        text: "USER",
        disabled: true,
        href: "/users",
      },
    ],
  }),
};
</script>

<style scoped>
.me-red {
  border: 1px solid #ebedf0;
}
</style>
