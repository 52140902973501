<template>
  <v-container class="">
    <v-row no-gutters>
      <v-app-bar color="white" prominent flat>
        <v-breadcrumbs :items="items"></v-breadcrumbs>
        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-app-bar>
    </v-row>
    <v-row no-gutters>
      <div class="country-title">
        <span class="country-flag">
          <flag :iso="country.alpha2Code" :squared="false" />
        </span>
        <span class="country-name">{{ countryName }}</span>
      </div>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="6">
        <v-row no-gutters class="pt-4">
          <v-col cols="12" md="6">
            <v-card class="mx-auto my-6" max-width="280" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 green--text">
                    TOTAL
                  </div>
                  <v-list-item-title class="headline mb-1">
                    {{ total }}
                  </v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions> </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="mx-auto my-6" max-width="280" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 green--text">
                    APPROVED
                  </div>
                  <v-list-item-title class="headline mb-1">
                    {{ approved }}
                  </v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions> </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters class="pb-6">
          <v-col cols="12" lg="4">
            <v-card class="mx-auto my-6 pa" max-width="180" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 amber--text" color="red">
                    AMENDMENTS
                  </div>
                  <v-list-item-title class="headline mb-1">
                    {{ ammendments }}
                  </v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions> </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" lg="4">
            <v-card class="mx-auto my-6" max-width="180" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 light-blue--text">
                    PROCEEDING
                  </div>
                  <v-list-item-title class="headline mb-1">
                    {{ proceeding }}
                  </v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions> </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" lg="4">
            <v-card class="mx-auto my-6" max-width="180" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 purple--text">
                    IN MOHE
                  </div>
                  <v-list-item-title class="headline mb-1">
                    {{ inMOHE }}
                  </v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions> </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <country-pie-chart></country-pie-chart>
      </v-col>

      <v-col cols="12" md="6" class="pa-4">
        <country-mou-table></country-mou-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CountryTable from "@/components/countries/CountryTable.vue";
import CountryMouTable from "@/components/countries/CountryMouTable.vue";
import CountryPieChart from "@/components/countries/CountryPieChart.vue";

export default {
  components: {
    CountryTable,
    CountryMouTable,
    CountryPieChart,
  },
  props: ["code"],
  data: () => ({}),
  computed: {
    country() {
      const countries = this.$store.getters["countries/countries"];
      return countries.find((el) => el.alpha3Code.toLowerCase() === this.code);
    },
    countryName() {
      if (this.country == null) {
        return "";
      }
      return this.country.englishShortName;
    },
    ammendments() {
      return 10;
    },

    proceeding() {
      return 14;
    },

    inMOHE() {
      return 20;
    },

    approved() {
      return 24;
    },
    total() {
      return this.ammendments + this.proceeding + this.inMOHE + this.approved;
    },
    items() {
      return [
        {
          text: "DASHBOARD",
          disabled: false,
          href: "breadcrumbs_dashboard",
        },
        {
          text: "COUNTRIES",
          disabled: false,
          href: "breadcrumbs_link_1",
        },
        {
          text: this.country.englishShortName,
          disabled: true,
          href: "breadcrumbs_link_2",
        },
      ];
    },
  },
};
</script>

<style scoped>
.me-red {
  border: 1px solid #ebedf0;
}

.country-name {
  font-size: 24px;
  margin: 0px 10px;
}

.country-flag {
  font-size: 24px;
  margin: 0px 10px;
}
</style>
