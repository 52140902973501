<template>
  <v-container class="">
    <v-row no-gutters>
      <v-app-bar color="white" flat>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-app-bar>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto login-container-card rounded-0" flat>
          <v-row no-gutters>
            <v-col cols="12" md="5">
              <v-card outlined color="#8f94fb" height="400" class="rounded-0">
                <div class="context pt-10">
                  <img
                    :src="require('@/assets/mohe-logo.png')"
                    alt="logo"
                    width="240"
                  />
                  <h1>Ministry of Higher Education</h1>
                </div>

                <div class="area">
                  <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="7">
              <v-card outlined height="400" class="pa-4 rounded-0">
                <v-form lazy-validation>
                  <v-text-field
                    v-model="username"
                    label="USERNAME"
                    placeholder="USERNAME"
                    outlined
                    required
                    dense
                    class="mt-12 mb-8 mx-4"
                    persistent-placeholder
                  ></v-text-field>

                  <v-text-field
                    v-model="password"
                    label="PASSWORD"
                    outlined
                    required
                    dense
                    class="mt-12 mb-8 mx-4"
                    placeholder="PASSWORD"
                    persistent-placeholder
                    type="password"
                  ></v-text-field>

                  <v-btn
                    :color="buttonType"
                    class="pa-6"
                    block
                    @click="login()"
                    :loading="loading"
                  >
                    {{ message }}
                  </v-btn>
                </v-form>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    username: "",
    password: "",
  }),
  computed: {
    user() {
      return this.$store.getters["auth/authUser"];
    },

    loginActionStatus() {
      return this.$store.getters["app/apiActionStatus"]["login"] || null;
    },

    loading() {
      if (this.loginActionStatus) {
        return this.loginActionStatus.isActive;
      }
      return false;
    },

    message() {
      if (!this.loginActionStatus) {
        return "LOGIN";
      }

      if (this.loginActionStatus.status == "failed") {
        return "LOGIN FAILED! RETRY";
      }

      if (
        this.loginActionStatus &&
        this.loginActionStatus.status == "success"
      ) {
        return "LOGIN SUCCESS!";
      }
    },

    buttonType() {
      if (this.loginActionStatus && this.loginActionStatus.status == "failed") {
        return "error";
      }

      return "success";
    },
  },
  watch: {
    user(value) {
      if (value.userId !== null && value.userId !== undefined) {
        console.log(value);
        this.$router.push("/");
      }
    },
  },
  methods: {
    login() {
      const credentials = {
        username: this.username,
        password: this.password,
      };

      this.$store.dispatch("auth/login", credentials);
    },
  },
};
</script>

<style>
.context {
  width: 100%;
  position: absolute;
  top: 10%;
  text-align: center;
}

.login-container-card {
  width: 75%;
}

.context h1 {
  text-align: center;
  color: #fff;
  font-size: 24px;
}

.area {
  background: #4e54c8;
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
  width: 100%;
  height: 100%;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 14px;
  height: 14px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 40px;
  height: 40px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 14px;
  height: 14px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 14px;
  height: 14px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 30px;
  height: 30px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 14px;
  height: 14px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 65px;
  height: 65px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 75px;
  height: 75px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 8px;
  height: 8px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 75px;
  height: 75px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.area {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
