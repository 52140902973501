<template>
  <v-card flat class="border mx-auto">
    <v-card flat>
      <div class="pa-2 mx-4">
        <v-btn block tile color="info" @click="showAddUserDialog()">
          ADD USER
        </v-btn>
      </div>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        dense
        outlined
        class="ma-6"
      ></v-text-field>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      class="v-align"
    >
      >
      <template v-slot:item.name="{ item }">
        {{ toTitleCase(item.name) }}
      </template>
      <template v-slot:item.type="{ item }">
        <v-chip
          class="ma-2 lable"
          :color="getUserColor(item.type)"
          outlined
          label
          small
        >
          <v-icon left size="16px">
            {{ getUserIcon(item.type) }}
          </v-icon>

          {{ item.type.toUpperCase() }}
        </v-chip>
      </template>
      <template v-slot:item.status="{ item }">
        <v-btn icon color="blue" @click="goToUser(item)">
          <v-icon size="20px">mdi-open-in-new</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "NAME", value: "name" },
        { text: "TYPE", value: "type" },
        { text: "ACTION", align: "center", value: "status" },
      ],
      message: "",
    };
  },
  computed: {
    users() {
      return this.$store.getters["users/users"];
    },

    filteredUsers() {
      let selection = this.activeTab;
      if (selection == 0) {
        return this.mous;
      }

      if (selection == 1) {
        return this.mous.filter((el) => el.status == "approved");
      }

      if (selection == 2) {
        return this.mous.filter((el) => el.status == "proceeding");
      }

      if (selection == 3) {
        return this.mous.filter((el) => el.status == "amendments");
      }
      if (selection == 4) {
        return this.mous.filter((el) => el.status == "in mohe");
      }
    },
  },
  methods: {
    goToUser(user) {
      this.$router.push({
        name: "user",
        params: { id: user._id },
      });
    },

    showAddUserDialog() {
      this.$store.dispatch("users/setAddUserDialogVisibility", true);
      this.loadInstitutes();
    },

    getUserColor(item) {
      if (item == "administrator") {
        return "#e91e63";
      }
      return "#4CAF50";
    },

    getUserIcon(user) {
      if (user == "administrator") {
        return "mdi-account-star-outline";
      }
      return "mdi-account-outline";
    },

    toTitleCase(text) {
      return text
        .split(" ")
        .map((word) => {
          return word[0].toUpperCase() + word.substring(1);
        })
        .join(" ");
    },

    getDate(value) {
      const date = new Date(value);
      return (
        date.getDate().toString() +
        "-" +
        (date.getMonth() + 1).toString() +
        "-" +
        date.getFullYear().toString()
      );
    },

    loadUsersData() {
      this.$store.dispatch("users/getUsers");
    },

    loadInstitutes() {
      this.$store.dispatch("institutes/getInstitutes");
    },
  },
  mounted() {
    this.loadUsersData();
  },
};
</script>

<style>
table td,
table th {
  vertical-align: middle !important;
}

.lable {
  width: 140px !important;
}
</style>
